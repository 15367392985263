<script>
import { ArrowUpIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import mailbox from "../Components/mailbox";

/**
 * Page-services component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    mailbox,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Digital Transofmration</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Services</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Digital Transformation
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row text-center features p-3">
          <p class="h3">
            We’ve aligned ourselves with strategic manufacturers to provide you
            best in class solutions that fit your needs.
          </p>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/refresh.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Disaster Recovery & Replication</h4>
                <p class="text-muted mb-0">
                  In the event of a disaster, we ensure that your data is
                  protected and easily accessible to keep your business running.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/database.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Data Storage</h4>
                <p class="text-muted mb-0">
                  We help you collect and store your data in the most efficient
                  way possible. We tailor solutions that scale with you.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/intellectual.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Data Protection</h4>
                <p class="text-muted mb-0">
                  We help protect and secure your data from outside factors and
                  attacks.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/fast.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Data Center Moves and Migrations</h4>
                <p class="text-muted mb-0">
                  We will help map out your data center move and data migration
                  from start to finish with minimal downtime. Whether you are
                  moving across the street or cross country, PromiTech can help.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/server.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Virtualization</h4>
                <p class="text-muted mb-0">
                  Virtualization helps simplify your data center management to
                  solve performance, minimise or eliminate downtime and decrease
                  operating cost.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features">
              <div class="image position-relative d-inline-block">
                <img
                  src="images/icon/computer.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Compute</h4>
                <p class="text-muted mb-0">
                  We make sure that your data has the needed computation power
                  for secure and fast access when needed.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6 order-1 order-md-1">
            <div class="card border-0">
              <div class="card-body p-0">
                <img
                  src="images/illustrator/envelope.svg"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-2">
            <mailbox />
          </div>
          <!--end col-->

          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature Start -->

    <!-- Project Start -->

    <!--end section-->
    <!-- Project End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--<Switcher /> -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
